import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import RelatedResourceCard from "../components/RelatedResourceCard"
import MetaData from "../components/meta"

// Styles
import "../styles/pilot.scss"

// Images
import hero__logo from "../images/pilot/hero/pilot-logo.svg"
import hero__illustration from "../images/pilot/hero/illustration.svg"
import intro__shape1 from "../images/pilot/intro/shape-01.svg"
import intro__shape2 from "../images/pilot/intro/shape-02.svg"
import intro__video from "../images/pilot/intro/registration.mp4"
import testimonials__quotes from "../images/pilot/testimonials/quotes-shape.svg"
import testimonials__shape from "../images/pilot/testimonials/shape-03.svg"
import features__shape1 from "../images/pilot/features/shape-04.svg"
import features__metrics from "../images/pilot/features/metrics.mp4"
import features__alerting from "../images/pilot/features/alerting-v2.mp4"
import features__plugin from "../images/pilot/features/plugin-v2.mp4"
import benefits__secure from "../images/pilot/benefits/secure.svg"
import benefits__ingress from "../images/pilot/benefits/ingress.svg"
import benefits__customize from "../images/pilot/benefits/customize-traefik.svg"

const PilotPage = ({ data, location }) => {
  const related_resources = data.related_resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="page-pilot">
        {/* Hero */}
        <section className="pilot-hero">
          <div className="pilot-hero__wrapper">
            <div className="pilot-hero__main">
              <div
                className="pilot-hero__announcement"
                dangerouslySetInnerHTML={{
                  __html: data.pilot_announcement?.html,
                }}
              ></div>
              <h1 className="pilot-hero__logo">
                <img
                  src={hero__logo}
                  alt="Pilot - The Control Center for Traefik"
                />
              </h1>
              <h2>The Control Center for Traefik</h2>
              <h3>Monitoring and management platform for Traefik.</h3>
              <a
                href="https://pilot.traefik.io/"
                className="button--primary button--primary--no-gradient button--primary--orange button--extra-padding"
              >
                Get Started
              </a>
            </div>
            <div className="pilot-hero__illustration">
              <img src={hero__illustration} alt="Pilot illustration" />
            </div>
          </div>
        </section>

        {/* Introduction */}
        <section className="pilot-intro page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What is Traefik Pilot?</h2>
            </div>
            <div className="columns">
              <div className="column-text">
                <p>
                  Traefik Pilot is a platform that centrally manages all Traefik
                  instances running in any environment.
                </p>
                <p>
                  Traefik Pilot provides observability and control of your
                  Traefik instances through a unified dashboard, instantly
                  equipping teams with detailed networking metrics, server
                  monitoring, and security notifications.
                </p>
                <p>
                  Traefik Pilot also hosts a public plugins hub for custom
                  middleware plugins, enabling traffic shaping, traffic QoS,
                  traffic rate limiting, and more.
                </p>
              </div>
              <div className="column-media">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop>
                    <source src={intro__video} type="video/mp4" />
                  </video>
                </div>
                <img src={intro__shape2} className="pilot-intro__shape2" />
                <img src={intro__shape1} className="pilot-intro__shape1" />
              </div>
            </div>
            <div className="pilot-intro__cta">
              <div>
                <h3>Already a Traefik user?</h3>
                <a
                  href="https://pilot.traefik.io/"
                  className="button--primary button--primary--no-gradient button--primary--orange button--extra-padding"
                >
                  Connect your Traefik instances
                </a>
              </div>
            </div>
          </div>
          <div className="pilot-intro__shapes"></div>
        </section>

        {/* Testimonials */}
        <section className="pilot-testimonials">
          <div className="wrapper-800">
            <blockquote>
              <img src={testimonials__quotes} />
              <p>
                Traefik Pilot delivers an entirely new approach to network
                management in the cloud. Undoubtedly, one of the enormous
                strengths of Pilot is its modularity, allowing the community to
                extend its functionality.
              </p>
              <footer>
                Teddy Ferdinand,
                <cite>Cloud Security Architect at WeScale</cite>
              </footer>
            </blockquote>
          </div>
          <img
            src={testimonials__shape}
            className="pilot-testimonials__shape"
          />
        </section>

        {/* Features */}
        <div className="pilot-features page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Traefik Pilot Features</h2>
              <a
                href="https://pilot.traefik.io/"
                className="button--primary button--primary--no-gradient button--primary--orange button--extra-padding button--primary--lowercase"
              >
                Get Started with Pilot
              </a>
            </div>

            <div className="columns">
              <div className="column-text">
                <h3>
                  Register, visualize and monitor all your Traefik instances in
                  one place
                </h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Centralized view of all your Traefik instances running in
                      any deployment
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Visualize networking metrics for each and all Traefik
                      servers, including as Requests per Second, Total Requests,
                      and Request Duration
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Real-time health monitoring of the Traefik services
                    </span>
                  </li>
                </ul>
              </div>
              <div className="column-media">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop>
                    <source src={features__metrics} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div className="pilot-features__shape1">
              <img src={features__shape1} />
            </div>

            <div className="columns columns--reverse">
              <div className="column-media">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop>
                    <source src={features__alerting} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="column-text">
                <h3>
                  Receive security vulnerability updates for your registered
                  instances
                </h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Built-in heartbeat mechanism checks-in with Pilot
                      periodically to report the health and availability of your
                      Traefik instance
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Automatically check for common vulnerabilities and
                      exposure (CVE) bulletins that apply to your Traefik
                      installation and configuration
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Alerting and issues notification through email or custom
                      webhooks configuration
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="pilot-features__shape2">
              <img src={features__shape1} />
            </div>

            <div className="columns">
              <div className="column-text">
                <h3>
                  Build, use, and share your own custom extensions to Traefik’s
                  functionality
                </h3>
                <ul className="list--with-check-icon">
                  <li>
                    <span className="text">
                      Extend the capabilities of Traefik with add-on packages
                      that allow you to apply transformations and enhancements
                      to both requests and responses, which are handled by
                      Traefik (network filtering, dynamic URL rewrites, and
                      more)
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Write, publish, and share your custom plugins with the
                      community
                    </span>
                  </li>
                  <li>
                    <span className="text">
                      Install middleware plugins directly from the catalog to
                      your Traefik instances
                    </span>
                  </li>
                </ul>
              </div>
              <div className="column-media">
                <div className="fake-browser">
                  <div className="fake-browser__controls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <video autoPlay muted loop>
                    <source src={features__plugin} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Benefits */}
        <div className="pilot-benefits page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Pilot Benefits</h2>
            </div>
            <ul>
              <li>
                <img src={benefits__secure} alt="Ensure security compliance" />
                <h3>Ensure security compliance</h3>
                <p>
                  Audit and validate Traefik instance are up to date with real
                  time CVE notifications
                </p>
              </li>
              <li>
                <img
                  src={benefits__ingress}
                  alt="Complete visibility of your instances in one place"
                />
                <h3>Complete visibility of your instances in one place</h3>
                <p>
                  Always-on monitoring and alerts for all your Traefik instances
                  running any on-premise or cloud deployment across multiple
                  availability zones and geographies.
                </p>
              </li>
              <li>
                <img
                  src={benefits__customize}
                  alt="Customize your Traefik experience"
                />
                <h3>Customize your Traefik experience</h3>
                <p>
                  Tailor Traefik to your needs with custom middleware plugins
                </p>
              </li>
            </ul>
          </div>
        </div>

        {/* Resources */}
        {related_resources.length > 0 && (
          <div className="pilot-resources page-content-section">
            <div className="wrapper-1200">
              <div className="page-content-section__header">
                <hr className="hr-tiny" />
                <h2>Learn More About Pilot</h2>
              </div>
              <div className="related-resource-feed">
                {related_resources.map(({ node }) => (
                  <RelatedResourceCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* CTA */}
        <div className="pilot-cta page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <h2>Get started with Pilot</h2>
            </div>
            <div className="pilot-cta__buttons">
              <a
                href="https://pilot.traefik.io/"
                className="button--primary button--primary--orange button--extra-padding button--primary--no-gradient"
              >
                Get Pilot Free
              </a>
              {/* <a
                href=""
                className="button--primary button--primary--white button--extra-padding"
              >
                Documentation
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "pilot" }) {
      ...PageMetaFields
    }

    pilot_announcement: ghostPage(
      slug: { eq: "pilot-updates-announcement-banner" }
    ) {
      html
    }

    related_resources: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-pilot-related-resource" } } }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`

export default PilotPage
